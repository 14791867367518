.bid {
  overflow: hidden;
  background-color:#b22222;
  color:beige;        
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

.offer {
  overflow: hidden;
  background-color:#008000;
  color: beige;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

.contractBalance {
  overflow: hidden;
  background-color: #7474aa;
  color: beige;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}
.buySellButton {
  background-color: darkgoldenrod;
  color:black
}
.img {
  width: 100%;
  height: 5rem;
}

.midPrice {
  overflow: hidden;
  background-color: #120f2c;
  color: beige;
  justify-content: center;
  text-align: center;
  padding: 1rem;  
}

.pnl {
  overflow: hidden;
  background-color: #b36b00;
  color: beige;
  justify-content: center;
  text-align: center;
  padding: 1rem; 
}